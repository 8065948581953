import "./styles.scss";

import React from "react";

const mainClass = "how-its-working__conditions-baner";

const ConditionsBaner = () => {
  return (
    <div className={mainClass} id="elastyczna-umowa">
      <div className="container">
        <h2>
          Każdy projekt oferuje
          <br />
          <span>indywidualne warunki dla inwestorów.</span>
        </h2>
        <p>
          Wybór właściwej oferty w morzu możliwości to nie lada wyzwanie,
          dlatego zachęcamy do kontaktu z doradcami condo.pl. Nasi eksperci nie
          tylko pomogą w wyborze inwestycji dopasowanej do potrzeb, a także
          przeprowadzą przez cały proces zakupu i wszystkie formalności.
        </p>
      </div>
    </div>
  );
};

export default ConditionsBaner;
