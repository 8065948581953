import "./styles.scss";

import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";

import { useApp } from "context/app";

import MessageIcon from "icons/message.svg";
import { Link } from "gatsby";

const mainClass = "how-its-works-menu";

const Menu = () => {
  const [active, setActive] = useState(null);
  const [section_positions, setSectionPostions] = useState([]);

  const menu_items = [
    {
      key: "jak-to-dziala",
      label: "Jak to działa",
    },
    {
      key: "korzysci-z-inwestycji",
      label: "Korzyści z inwestycji",
    },
    {
      key: "profesjonalny-operator",
      label: "Profesjonalny operator",
    },
    {
      key: "pobyty-wlascicielskie",
      label: "Pobyty właścicielskie",
    },
    {
      key: "dobra-inwestycja-condo",
      label: "Dobra inwestycja condo",
    },
    {
      key: "elastyczna-umowa",
      label: "Elastyczna umowa",
    },
    {
      key: "contact-form",
      type: "contact",
      icon: <MessageIcon />,
      label: "Skontaktuj się z nami",
    },
  ];

  const scrollToSection = (e, index) => {
    e.preventDefault();
    const element = document.getElementById(menu_items[index]?.key);

    window.history.pushState(
      {},
      "",
      `/jak-to-dziala#${menu_items[index]?.key}`
    );

    window.scroll({
      top: element.offsetTop - 180,
      left: 0,
      behavior: "smooth",
      block: "start",
    });
  };

  const handleScroll = useCallback((e) => {
    if (e.currentTarget.scrollY < section_positions[0]) {
      setActive(0);
    } else if (
      e.currentTarget.scrollY > section_positions[section_positions?.length - 1]
    ) {
      setActive(section_positions?.length - 1);
    } else {
      const find_index = section_positions?.findIndex(
        (item, index) =>
          e.currentTarget.scrollY > item - 200 &&
          e.currentTarget.scrollY < section_positions[index + 1] - 200
      );

      setActive(find_index);
    }
  });

  useEffect(() => {
    if (section_positions.length === 0) {
      const positions = menu_items?.map(
        ({ key }) => document.getElementById(key)?.offsetTop
      );
      setSectionPostions(positions);
    }

    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [handleScroll]);

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__items`}>
          {menu_items?.map(({ key, icon, label }, index) => (
            <Link
              key={index}
              to={`/jak-to-dziala#${key}`}
              onClick={(e) => scrollToSection(e, index)}
              className={classnames(`${mainClass}__items__item`, {
                // [`${mainClass}__items__item--active`]: active === index,
                [`${mainClass}__items__item--with-icon`]: !!icon,
                [`${mainClass}__items__item--choose-apartament`]:
                  key === "choose-apartament",
              })}
            >
              {icon}
              <span>{label}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Menu;
