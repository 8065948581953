import "styles/pages/home-page.scss";

import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import {
  ConditionsBaner,
  CondoModel,
  GoodInvestment,
  Income,
  LuxuryApartments,
  ProfessionalOperator,
} from "page_components/how-its-working";

import Layout from "components/Layout";
import PageHeader from "components/PageHeader";

import AboutHero from "images/how-its-working-bg.png";
import Menu from "../page_components/how-its-working/components/Menu";

const HowItsWorking = ({ data, location }) => {
  const { hash, pathname } = location;

  const [init_loading, setInitLoading] = useState(false);
  const how_it_works = data.howItWorks.howItWorks;

  const seo = data.howItWorks.seo;
  const schema = JSON.parse(data.howItWorks.seo.schema.raw);

  useEffect(() => {
    if (!!!hash) return;

    const elem = document.querySelector(`${hash.split("?")[0]}`);
    if (!!init_loading) {
      window.scrollBy({
        top: elem.getBoundingClientRect().top - 180,
        behavior: "smooth",
      });
    } else {
      setTimeout(() => {
        window.scrollBy({
          top: elem.getBoundingClientRect().top - 180,
          behavior: "smooth",
        });
      }, 100);
    }

    setInitLoading(true);
    // eslint-disable-next-line
  }, [location]);

  return (
    <Layout
      seo={{ title: seo?.title, desc: seo?.metaDesc, schema }}
      location="Jak to działa"
      blue_header
      card_remove
    >
      <Menu />
      <PageHeader
        image={AboutHero}
        title="Jak to działa?"
        thin_title={
          "<strong>Wejdź do świata hoteli</strong><br />i zostań właścicielem apartamentu!"
        }
      />
      <CondoModel
        text={how_it_works?.condoText}
        items={how_it_works?.condoBenefits}
      />
      <Income
        title={how_it_works?.earnRevenueTitle}
        description={how_it_works?.earnRevenueDesc}
        items={how_it_works?.earnRevenueItems}
      />
      <ProfessionalOperator
        title={how_it_works?.professionalOperatorTitle}
        descriptions={how_it_works?.professionalOperatorDescriptions}
        items={how_it_works?.professionalOperatorItems}
      />
      <LuxuryApartments
        title={how_it_works?.luxuriousApartmentTitle}
        description={how_it_works?.luxuriousApartmentDesc}
      />
      <GoodInvestment items={how_it_works?.goodInvestmentItems} />
      <ConditionsBaner />
    </Layout>
  );
};

export default HowItsWorking;

export const query = graphql`
  query howItWorks {
    howItWorks: wpPage(id: { eq: "cG9zdDoxMDk0" }) {
      howItWorks {
        condoText
        condoBenefits {
          icon
          title
          desc
        }
        earnRevenueTitle
        earnRevenueDesc
        earnRevenueItems {
          icon
          desc
        }
        professionalOperatorTitle
        professionalOperatorDescriptions {
          text
        }
        professionalOperatorItems {
          icon
          title
          desc
        }
        luxuriousApartmentTitle
        luxuriousApartmentDesc
        goodInvestmentItems {
          icon
          title
          desc
        }
      }
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`;
