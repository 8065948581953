import "./styles.scss";
import React from "react";

import getIcon from "libs/helpers/getIcon";

import ApartmentIcon from "icons/condo-apartment.svg";
import Menu from "../components/Menu";

const mainClass = "how-its-working__condo-model";

const CondoModel = ({ text, items }) => {
  return (
    <div className={mainClass} id="jak-to-dziala">
      <div className="container">
        <div className={`${mainClass}__header`}>
          <div className={`${mainClass}__header__icon`}>
            <ApartmentIcon />
            <h2>“Condo”</h2>
          </div>
          <div className={`${mainClass}__header__text`}>
            <p>{text}</p>
          </div>
        </div>
        <div className={`${mainClass}__items`}>
          {items?.map(({ icon, title, desc }, index) => (
            <div key={index} className={`${mainClass}__items__item`}>
              {getIcon(icon)}
              <span dangerouslySetInnerHTML={{ __html: title }} />
              <p>{desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CondoModel;
