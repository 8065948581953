import "./styles.scss";

import React from "react";

import getIcon from "libs/helpers/getIcon";

const mainClass = "how-its-working__good-investment";

const GoodInvestment = ({ items }) => {
  const formatted_items = items?.map(({ icon, title, desc: text }) => ({
    icon: getIcon(icon),
    title,
    text,
  }));

  return (
    <div className={mainClass} id="dobra-inwestycja-condo">
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h2>
            Dobra inwestycja condo,
            <br />
            <span>czyli jaka?</span>
          </h2>
        </div>
        <div className={`${mainClass}__items`}>
          {formatted_items?.map(({ icon, title, text }, index) => (
            <div key={index} className={`${mainClass}__items__item`}>
              <div className={`${mainClass}__items__item__icon`}>{icon}</div>
              <div className={`${mainClass}__items__item__text`}>
                <label>{title}</label>
                <p>{text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GoodInvestment;
