import "./styles.scss";

import React from "react";

import getIcon from "libs/helpers/getIcon";

const mainClass = "how-its-working__income";

const Income = ({ title, description, items }) => {
  return (
    <div className={mainClass} id="korzysci-z-inwestycji">
      <div className="container">
        <div className={`${mainClass}__header`}>
          <div className={`${mainClass}__header__text`}>
            <h2
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            <p>{description}</p>
          </div>
        </div>
        <div className={`${mainClass}__items`}>
          {items?.map(({ icon, desc }, index) => (
            <div key={index} className={`${mainClass}__items__item`}>
              {getIcon(icon)}
              <span
                dangerouslySetInnerHTML={{
                  __html: desc.split("|").join("<br/>"),
                }}
              ></span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Income;
