import "./styles.scss";

import React from "react";

import WomanImage from "images/how-its-working-woman.png";

const mainClass = "how-its-working__luxury-apartments";

const LuxuryApartments = ({ title, description }) => {
  return (
    <div className={mainClass} id="pobyty-wlascicielskie">
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h2
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <p>{description}</p>
        </div>
        <div className={`${mainClass}__image`}>
          <img src={WomanImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default LuxuryApartments;
