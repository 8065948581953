import "./styles.scss";

import React from "react";

import getIcon from "libs/helpers/getIcon";
import FacilitiesGrid from "components/FacilitiesGrid";

import CondoPinIcon from "icons/condo-pin.svg";

const mainClass = "how-its-working__professional-operator";

const ProfessionalOperator = ({ title, descriptions, items }) => {
  const formatted_items = items?.map(({ icon, title, desc: text }) => ({
    icon: getIcon(icon),
    title,
    text,
  }));

  return (
    <div className={mainClass} id="profesjonalny-operator">
      <div className="container">
        <div className={`${mainClass}__header`}>
          <CondoPinIcon />
          <h2
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </div>
        <div className={`${mainClass}__text__items`}>
          {descriptions?.map(({ text }, index) => (
            <div key={index} className={`${mainClass}__text__items__item`}>
              <p>{text}</p>
            </div>
          ))}
        </div>
        <FacilitiesGrid data={formatted_items} variant="dark" />
      </div>
    </div>
  );
};

export default ProfessionalOperator;
